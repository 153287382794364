import verticalNavigation from '@/navigation/vertical';
import horizontalNavigation from '@/navigation/horizontal';
import { isArray, concat, assign } from 'lodash';
export default {
    namespaced: true,
    state: {
        shallContentShowOverlay: false,
        navigation: {
            vertical: verticalNavigation,
            horizontal: horizontalNavigation,
        },
        snackbar: {
            color: 'primary',
            text: '',
            timeout: 5000,
            show: false,
        },
    },
    getters: {
        verticalNavigation: (state) => state.navigation.vertical,
        horizontalNavigation: (state) => state.navigation.horizontal,
        snackbar: (state) => state.snackbar,
    },
    mutations: {
        TOGGLE_CONTENT_OVERLAY(state, value) {
            state.shallContentShowOverlay = value !== undefined ? value : !state.shallContentShowOverlay;
        },
        ADD_VERTICAL_NAVIGATION_ITEM(state, item) {
            if (isArray(item)) {
                state.navigation.vertical = concat(state.navigation.vertical, item);
            } else {
                state.navigation.vertical.push(item);
            }
        },
        ADD_HORIZONTAL_NAVIGATION_ITEM(state, item) {
            if (isArray(item)) {
                state.navigation.horizontal = concat(state.navigation.horizontal, item);
            } else {
                state.navigation.horizontal.push(item);
            }
        },
        RESET_VERTICAL_NAVIGATION(state) {
            state.navigation.vertical = verticalNavigation;
        },
        RESET_HORIZONTAL_NAVIGATION(state) {
            state.navigation.horizontal = horizontalNavigation;
        },
        RESET_NAVIGATION(state) {
            state.navigation = {
                vertical: verticalNavigation,
                horizontal: horizontalNavigation,
            };
        },
        SET_SNACKBAR(state, snackbar) {
            state.snackbar = assign({}, snackbar);
        },
    },
    actions: {
        addVerticalNavigationItem({ commit }, items) {
            commit('ADD_VERTICAL_NAVIGATION_ITEM', items);
        },
        addHorizontalNavigationItem({ commit }, items) {
            commit('ADD_HORIZONTAL_NAVIGATION_ITEM', items);
        },
        snackbar({ commit }, snackbar) {
            commit('SET_SNACKBAR', snackbar);
        },
    },
};
