import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import moloni_icon from '../../assets/icons/moloni_icon.svg';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        values: {
            customIconSvg: moloni_icon,
        },
    },
});
