import axios from '@axios';

export default {
    namespaced: true,
    state: {
        calendarOptions: [],
        selectedCalendars: [],
        events: [],
        contacts: [],
    },
    getters: {
        getCalendarOptions(state) {
            return state.calendarOptions;
        },
        getSelectedCalendars(state) {
            return state.selectedCalendars;
        },
        getEvents(state) {
            return state.events;
        },
        getContacts(state) {
            return state.contacts;
        },
    },
    mutations: {
        setCalendarOptions(state, calendars) {
            state.calendarOptions = calendars;
        },
        setSelectedCalendars(state, val) {
            state.selectedCalendars = val;
        },

        setEvents(state, val) {
            state.events = val;
        },
        setContacts(state, val) {
            state.contacts = val;
        },
    },
    actions: {
        /* fetchEvents({ state }) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/apps/calendar/events', {
                        params: {
                            calendars: state.selectedCalendars.join(','),
                        },
                    })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        addEvent(ctx, { event }) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/apps/calendar/events', { event })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        updateEvent(ctx, { event }) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/apps/calendar/events/${event.id}`, { event })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        removeEvent(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/apps/calendar/events/${id}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        }, */
    },
};
