export default {
    GoogleSendPermissions: {
        error: 'Error',
        errorText: 'An error occurred!',
    },

    CreateCalendar: {
        error: 'Error',
        errorText: 'An error occurred while creating a new calendar!',
        success: 'Success',
        successText: 'Calendar created successfully!',
        name: 'Name',
        description: 'Description',
        timezone: 'Timezone',
        create: 'Create',
        cancel: 'Cancel',
        required: 'Required',
        title: 'Create Calendar',
    },
    RemoveCalendarDialog: {
        title: 'Remove Calendar',
        text: 'Are you sure you want to remove this calendar? You will no longer have access to this calendar and its events. Other people with access to the calendar can continue to use it.',
        remove: 'Remove',
        cancel: 'Cancel',
        error: 'Error',
        errorText: 'An error occurred while removing this calendar!',
    },

    CalendarLeftSidebarContent: {
        createEvent: 'Create Event',
        calendars: 'Calendars',
        createCalendar: 'Create Calendar',
    },

    Calendar: {
        participate: 'Participate',
        attendees: 'Attendees',
        month: 'Month',
        week: 'Week',
        day: 'Day',
    },

    CreateEvent: {
        name: 'Name',
        calendar: 'Calendar',
        allDay: 'All Day',
        startDate: 'Start Date',
        endDate: 'End Date',
        startTime: 'Start Time',
        endTime: 'End Time',
        repetition: 'Repetition',
        createMeeting: 'Create Meeting',
        invite: 'Invite',
        location: 'Location',
        description: 'Description',
        addNotification: 'Add Notification',
        personalized: 'Personalized...',
        create: 'Create',
        clear: 'Clear',
        minutesBefore: '{n} minute before | {n} minutes before',
        hoursBefore: '{n} hour before | {n} hours before',
        daysBefore: '{n} day before | {n} days before',
        edit: 'Edit',
        event: 'Event',
        noRepeat: 'Does not repeat',
        daily: 'Daily',
        weekly: 'Weekly',
        monthly: 'Monthly',
        yearly: 'Yearly',
        everyWeekday: 'Every day of the week (Monday to Friday)',
        addMeeting: 'Add Google Meet Video Conferencing',
        removeMeeting: 'Remove Google Meet Video Conferencing',
        sameDayAt: 'Same day at 09:00',
        dayBeforeAt: 'The day before at 09:00 | {n} days before at 09:00',
        weekBeforeAt: '1 week before at 09:00 | {n} days before at',
        errorText: 'An error occurred while creating this event!',
    },

    RepetitionDialog: {
        frequency: 'frequency',
        occurrences: 'Occurrences',
        everyDay: 'Every day ',
        every: 'Every ',
        days: ' days ',
        everyDayUntil: 'Every day until ',
        daysUntil: ' days until ',
        times: '{n} time | {n} times',
        weeklyEveryDay: 'Weekly, every day ',
        weekly: 'Weekly, ',
        weeksEveryDay: ' weeks every day',
        weeks: ' weeks ',
        weeklyEveryDayUntil: 'Weekly, every day until ',
        weeklyEveryDayUntilLower: ' weekly, every day until ',
        until: ' ,until ',
        weeksEveryDayUntil: ' weeks every day, until ',
        monthlyOnTheDay: 'Monthly, on the ',
        monthsAt: ' months on the day ',
        months: ' months ',
        annuallyAt: 'Annualy, at ',
        yearsAt: ' years at ',
        annuallyUntil: 'Annualy, until ',
        annually: 'Annualy, ',
        years: ' years ',
        monthlyAt: 'Monthly on the day ',
        monthlyFirst: 'Monthly on the first ',
        monthlySecond: 'Monthly on the second ',
        monthlyThird: 'Monthly on the third ',
        monthlyFourth: 'Monthly on the fourth ',
        monthlyLast: 'Monthly in the last ',
        day: ' Day | Days',
        week: 'Week | Weeks',
        month: 'Month | Months',
        year: 'Year | Years',
        customFrequency: 'Custom frequency',
        save: 'Save',
        cancel: 'Cancel',
        repeatEvery: 'Repeat every',
        endsIn: 'Ends in',
        never: 'Never',
        after: 'After',
        in: 'In',
        repeat: 'Repeat',
        sunday: 'Sunday',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
    },
    DialogAllDayNotification: {
        customNotification: 'Custom Notification',
        time: 'time',
        cancel: 'Cancel',
        save: 'Save',
        notification: 'Notification',
        email: 'Email',
        days: 'Days',
        weeks: 'Weeks',
        beforeAt: ' before, at ',
        daysNumber: '{n} day | {n} days ',
        weeksNumber: '{n} week | {n} weeks ',
        byEmail: ' ,by email',
    },
    DialogNotAllDayNotification: {
        customNotification: 'Custom Notification',
        time: 'time',
        cancel: 'Cancel',
        save: 'Save',
        notification: 'Notification',
        email: 'Email',
        days: 'Days',
        weeks: 'Weeks',
        minutes: 'Minutes',
        hours: 'Hours',
        minutesNumber: '{n} minute before | {n} minutes before',
        hoursNumber: '{n} hour before| {n} hours before',
        daysNumber: '{n} day before| {n} days before',
        weeksNumber: '{n} week before| {n} weeks before',
        byEmail: ' ,by email',
    },

    RemoveEventDialog: {
        title: 'Remove Event',
        text: 'Are you sure you want to remove this event?',
        remove: 'Remove',
        cancel: 'Cancel',
        error: 'Error',
        errorText: 'An error occurred while removing this event!',
    },
};
