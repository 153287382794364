import { routeName } from '../../constants';
/* import customIconSvg from '../../assets/icons/moloni_icon'; */
const { mdiHomeAlert, mdiFileDocumentOutline, mdiReceipt, mdiPackageVariantClosed } = require('@mdi/js');

export default [
    {
        title: 'Moloni',
        icon: mdiHomeAlert,
        order: 1,
        children: [
            {
                title: 'Invoices',
                icon: mdiFileDocumentOutline,
                to: routeName('moloni-invoices'),
            },
            {
                title: 'Receipts',
                icon: mdiReceipt,
                to: routeName('moloni-receipts'),
            },
            {
                title: 'PurchaseOrders',
                icon: mdiPackageVariantClosed,
                to: routeName('moloni-purchase-orders'),
            },
        ],
    },
];
