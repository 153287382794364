<template>
    <v-menu offset-y left nudge-bottom="14" min-width="230" content-class="user-profile-menu-content">
        <template v-slot:activator="{ on, attrs }">
            <v-badge bottom :color="verifyColorStatus(profileUserData.status)" overlap offset-x="12" offset-y="12" class="ms-4" dot>
                <v-avatar size="40px" v-bind="attrs" color="primary" class="v-avatar-light-bg primary--text" v-on="on">
                    <v-img v-if="profileUserData.avatar" :src="profileUserData.avatar"></v-img>
                    <v-icon v-else color="primary" size="28">
                        {{ accountIcon }}
                    </v-icon>
                </v-avatar>
            </v-badge>
        </template>
        <v-list>
            <div class="pb-3 pt-2">
                <v-badge bottom :color="verifyColorStatus(profileUserData.status)" overlap offset-x="12" offset-y="12" class="ms-4" dot>
                    <v-avatar size="40px" color="primary" class="v-avatar-light-bg primary--text">
                        <v-img v-if="profileUserData.avatar" src="@/assets/images/avatars/1.png"></v-img>
                        <v-icon v-else color="primary" size="28">
                            {{ accountIcon }}
                        </v-icon>
                    </v-avatar>
                </v-badge>
                <div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align: middle">
                    <span class="text--primary font-weight-semibold mb-n1">
                        {{ profileUserData.fullName || profileUserData.username }}
                    </span>
                    <small class="text--disabled text-capitalize">{{ profileUserData.role }}</small>
                </div>
            </div>

            <v-divider></v-divider>
            <div class="pl-4 mt-3">
                <span class="text-xs text--disabled">STATUS</span>

                <v-radio-group v-model="profileUserData.status" class="mt-2">
                    <v-radio v-for="radioOption in userStatusRadioOptions" :key="radioOption.title" :label="radioOption.title" :value="radioOption.value" :color="radioOption.color"></v-radio>
                </v-radio-group>
            </div>
            <v-divider></v-divider>
            <v-list-item>
                <v-list-item-icon class="me-2">
                    <v-icon size="22">
                        {{ accountIcon }}
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Profile</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider class="my-2"></v-divider>

            <!-- Settings -->
            <v-list-item>
                <v-list-item-icon class="me-2">
                    <v-icon size="22">
                        {{ cogOutIcon }}
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Settings</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider class="my-2"></v-divider>

            <!-- Logout -->
            <v-list-item @click="logoutUser">
                <v-list-item-icon class="me-2">
                    <v-icon size="22">
                        {{ logoutIcon }}
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Logout</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
    import { mdiAccountOutline, mdiCogOutline, mdiLogoutVariant } from '@mdi/js';

    export default {
        data() {
            return {
                profileUserData: {},
                userStatusRadioOptions: [
                    { title: 'Online', value: 'online', color: 'success' },
                    { title: 'Away', value: 'away', color: 'warning' },
                    { title: 'Do not disturb', value: 'busy', color: 'error' },
                    { title: 'Offline', value: 'offline', color: 'secondary' },
                ],

                accountIcon: mdiAccountOutline,
                cogOutIcon: mdiCogOutline,
                logoutIcon: mdiLogoutVariant,
            };
        },

        methods: {
            verifyColorStatus(status) {
                if (status === 'online') {
                    return 'success';
                } else if (status === 'away') {
                    return 'warning';
                } else if (status === 'busy') {
                    return 'error';
                } else if (status === 'offline') {
                    return 'secondary';
                }
            },

            logoutUser() {
                this.$store.dispatch('auth/onUserLogout');
                this.$router.push({ name: 'Login' });
            },
        },

        created() {
            this.profileUserData = {
                id: 11,
                avatar: require('@/assets/images/avatars/1.png'),
                fullName: 'John Doe',
                role: 'admin',
                about: 'Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.',
                status: 'online',
                settings: {
                    isTwoStepAuthVerificationEnabled: true,
                    isNotificationsOn: false,
                },
            };
        },
    };
</script>

<style lang="scss">
    .user-profile-menu-content {
        .v-list-item {
            min-height: 2.5rem !important;
        }
    }
</style>
