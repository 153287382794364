import axios from '@axios';
import qs from 'qs';

export function getRoles(query = {}) {
    query = qs.stringify(query);
    return axios.get(`/plugin-roles?${query}`);
}

export function getPermissions(query = {}) {
    query = qs.stringify(query);
    return axios.get(`/plugin-permissions?${query}`);
}

export function addRole(data) {
    return axios.post('/plugin-roles', { data });
}

export function updateRole(id, data) {
    return axios.put(`/plugin-roles/${id}`, { data });
}

export function deleteRole(id) {
    return axios.delete(`/plugin-roles/${id}`);
}

export function countRoles(query = {}) {
    query = qs.stringify(query);
    return axios.get(`/plugin-roles/count?${query}`);
}
