import role from './role';
import media from './media';

export default {
    name: '',
    username: '',
    email: '',
    role: role,
    status: 'pending',
    picture: media,
    notifications: [],
};
