export default {
    views: {
        invoice: {
            preview: {
                invoice: 'Invoice',
                issuedDate: 'Issued Date',
                dueDate: 'Due Date',
                to: 'Invoice to',
                vat: 'Vat',
                productsColumns: {
                    reference: 'Reference',
                    ean: 'EAN',
                    name: 'Name',
                    quantity: 'Quantity',
                    unit: 'Unit',
                    unitPrice: 'Unit Price',
                    tax: 'Tax',
                    total: 'Total Liquid',
                },
                mbColumns: {
                    entity: 'Entity',
                    reference: 'Reference',
                    value: 'Value',
                    date: 'Date',
                    paymentStatus: 'Payment Status',
                    paymentDate: 'Payment Date',
                },
                taxesColumns: {
                    designation: 'Designation',
                    value: 'Value',
                    incidence: 'Incidence',
                    total: 'Total',
                },
                totalColumns: {
                    subTotal: 'Sub Total',
                    discount: 'Discount',
                    total: 'Total',
                },
                transportColumns: {
                    expedition: 'Expedition',
                    vehicle: 'Vehicle',
                    transactionDate: 'Transaction Date',
                    load: 'Load Local',
                    unload: 'Unload Local',
                },
            },
            list: {
                columns: {
                    id: 'ID',
                    status: 'Status',
                    client: 'Client',
                    total: 'Total',
                    date: 'Date',
                    balance: 'Balance',
                    actions: 'Actions',
                },
                paid: 'Paid',
                unpaid: 'Unpaid',
                partialPayment: 'Partial Payment',
                draft: 'Draft',
                pastDue: 'Past Due',
                see: 'See Invoice',
                download: 'Download',
                itemsPerPageText: 'Invoices per Page',
            },
        },
        receipt: {
            preview: {
                invoice: 'Invoice',
                issuedDate: 'Issued Date',
                dueDate: 'Due Date',
                to: 'Invoice to',
                vat: 'Vat',
                documentsColumns: {
                    type: 'Document Type',
                    number: 'Number',
                    date: 'Date',
                    totalLiquid: 'Total Liquid',
                    reconciledValue: 'Reconciled Value',
                },
                payments: 'Payments',
                paymentColumns: {
                    name: 'Name',
                    date: 'Date',
                    value: 'Value',
                    observations: '',
                },
                total: 'Total',
            },
            list: {
                columns: {
                    id: 'ID',
                    status: 'Status',
                    client: 'Client',
                    total: 'Total',
                    date: 'Date',
                    balance: 'Balance',
                    actions: 'Actions',
                },
                paid: 'Paid',
                unpaid: 'Unpaid',
                partialPayment: 'Partial Payment',
                draft: 'Draft',
                pastDue: 'Past Due',
                see: 'See Invoice',
                download: 'Download',
                itemsPerPageText: 'Invoices per Page',
            },
        },
        purchaseOrder: {
            preview: {
                purchaseOrder: 'Purchase Order',
                issuedDate: 'Issued Date',
                dueDate: 'Due Date',
                to: 'Invoice to',
                vat: 'Vat',
                productsColumns: {
                    reference: 'Reference',
                    ean: 'EAN',
                    name: 'Name',
                    quantity: 'Quantity',
                    unit: 'Unit',
                    unitPrice: 'Unit Price',
                    tax: 'Tax',
                    total: 'Total Liquid',
                },
                mbColumns: {
                    entity: 'Entity',
                    reference: 'Reference',
                    value: 'Value',
                    date: 'Date',
                    paymentStatus: 'Payment Status',
                    paymentDate: 'Payment Date',
                },
                taxesColumns: {
                    designation: 'Designation',
                    value: 'Value',
                    incidence: 'Incidence',
                    total: 'Total',
                },
                totalColumns: {
                    subTotal: 'Sub Total',
                    discount: 'Discount',
                    total: 'Total',
                },
                transportColumns: {
                    expedition: 'Expedition',
                    vehicle: 'Vehicle',
                    transactionDate: 'Transaction Date',
                    load: 'Load Local',
                    unload: 'Unload Local',
                },
            },
            list: {
                columns: {
                    id: 'ID',
                    status: 'Status',
                    client: 'Client',
                    total: 'Total',
                    date: 'Date',
                    balance: 'Balance',
                    actions: 'Actions',
                },
                paid: 'Paid',
                unpaid: 'Unpaid',
                partialPayment: 'Partial Payment',
                draft: 'Draft',
                pastDue: 'Past Due',
                see: 'See Invoice',
                download: 'Download',
                itemsPerPageText: 'Purchase Orders per Page',
            },
        },
    },
};
