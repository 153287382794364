//! REGISTER STORE MODULES IN MAIN STORE
//? store modules are registered in main store using the plugin id as prefix to avoid collisions with other plugins
//? consider a plugin with the id 'google-calendar'
//? e.g. store module with name "main" becomes accessible by "google-calendar/main"

import calendar from './calendar';

export default {
    calendar,
};
