const apps = [
    {
        path: '/apps/calendar',
        name: 'apps-calendar',
        component: () => import('@/views/apps/calendar/Calendar.vue'),
        meta: {
            layout: 'content',
        },
    },

    //
    //* ——— Chat ——————————————————
    //

    {
        path: '/apps/chat',
        name: 'apps-chat',
        component: () => import('@/views/apps/chat/Chat.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/apps/chat-test/:contactID',
        name: 'chat-test',
        component: () => import('@/views/apps/chat/ChatTest.vue'),
        meta: {
            layout: 'content',
        },
    },

    //
    //* ——— Rocket Chat ——————————————————
    //
    {
        path: '/chat/new-user',
        name: 'chat-new-user',
        component: () => import('@/views/apps/rocket-chat/AddUser.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/chat/new-room',
        name: 'chat-new-room',
        component: () => import('@/views/apps/rocket-chat/AddRoom.vue'),
        meta: {
            layout: 'content',
        },
    },
];

export default apps;
