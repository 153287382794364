export const state = () => ({
    token: '',
    user: {},
});

export const getters = {
    getToken: (state) => state.token,
    getUser: (state) => state.user,
};

export const mutations = {
    setToken: (state, payload) => {
        state.token = payload;
    },
    setUser: (state, payload) => {
        state.user = payload;
    },
};

export const actions = {
    onUserLogin({ commit }, payload) {
        commit('setToken', payload.jwt);
        commit('setUser', payload.user);
    },
    onUserLogout({ commit }) {
        commit('setToken', '');
        commit('setUser', {});
    },
};

export const namespaced = true;

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions,
};
