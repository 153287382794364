export default {
    List: {
        totalUsers: 'Total users',
        activeUsers: 'Active users',
        pendingUsers: 'Pending users',
        disabledUsers: 'Disabled users',
        searchFilter: 'Search & filter',
        selectRole: 'Select a role',
        selectStatus: 'Select a status',
        search: 'Search',
        addUser: 'Add new user',
        details: 'Details',
        delete: 'Delete',
        actions: 'Actions',
        deleteUser: 'Delete user',
        deleteUserConfirm: 'Are you sure you want to delete this user?',
        deleteSelected: 'Delete selected',
        deleteSelectedConfirm: 'Are you sure you want to delete selected users?',
        deleteManyUsers: 'Delete many users',
        deleteSuccess: 'User deleted successfully',
        deleteManySuccess: 'Users deleted successfully',
    },
    Form: {
        add: {
            user: 'Add new user',
            success: 'User added successfully',
        },
        edit: {
            user: 'Edit user',
            success: 'User updated successfully',
        },
        name: 'Name',
        username: 'Username',
        email: 'Email',
        password: 'Password',
        role: 'Role',
        status: 'Status',
        picture: 'Picture',
        save: 'Save',
        cancel: 'Cancel',
        requirePasswordChange: 'Require password change',
        generatePassword: 'Generate password and send by email',
        welcomeEmail: 'Send welcome email',
        updatePassword: "(If you don't want to change the password, leave the field empty)",
    },
    user: {
        user: 'User',
        name: 'Name',
        email: 'Email',
        role: 'Role',
        status: 'Status',
        statuses: {
            active: 'Active',
            pending: 'Pending',
            disabled: 'Disabled',
        },
    },
    validations: {
        uniqueUsername: 'Username already exists',
        usernameAvailable: 'Username available',
        uniqueEmail: 'Email already exists',
        emailAvailable: 'Email available',
    },
    role: {
        name: 'Name',
        description: 'Description',
        users: 'Users',
    },
    Roles: {
        List: {
            addRole: 'Add new role',
        },
        Form: {
            add: {
                title: 'Add new role',
                success: 'Role added successfully',
            },
            edit: {
                title: 'Edit role',
                success: 'Role updated successfully',
            },
            details: 'Details',
            permissions: 'Permissions',
            selectAll: 'Select all',
        },
    },
    yes: 'Yes',
    no: 'No',
    delete: 'Delete',
    cancel: 'Cancel',
};
