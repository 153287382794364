import { mdiFileOutline, mdiHomeOutline } from '@mdi/js';

export default [
    // {
    //     title: 'Home',
    //     icon: mdiHomeOutline,
    //     to: 'home',
    // },
    // {
    //     title: 'Second Page',
    //     icon: mdiFileOutline,
    //     to: 'second-page',
    // },
];
