import { extend } from 'vee-validate';
import { countRoles } from './api/roles';
import { countUsers } from './api/users';
import { getTrad } from './i18n/getTrad';
import slugify from 'slugify';

extend('uniqueEmail', {
    params: ['userId'],
    async validate(value, { userId }) {
        let valid = await countUsers({ email: value, ...(userId && { id: { $ne: userId } }) })
            .then(({ data }) => {
                if (data > 0) {
                    return false;
                }
                return true;
            })
            .catch(() => {
                return false;
            });

        return valid;
    },
    message: () => getTrad('validations.uniqueEmail'),
    lazy: true,
    castValue: (value) => value.toLowerCase(),
});

extend('uniqueUsername', {
    params: ['userId'],
    async validate(value, { userId }) {
        let valid = await countUsers({ username: value, ...(userId && { id: { $ne: userId } }) })
            .then(({ data }) => {
                if (data > 0) {
                    return false;
                }
                return true;
            })
            .catch(() => {
                return false;
            });

        return valid;
    },
    message: () => getTrad('validations.uniqueUsername'),
    lazy: true,
});

extend('uniqueRoleType', {
    params: ['roleId'],
    async validate(value, { roleId }) {
        value = slugify(value, { lower: true });
        let valid = await countRoles({ type: value, ...(roleId && { id: { $ne: roleId } }) })
            .then(({ data }) => {
                if (data > 0) {
                    return false;
                }
                return true;
            })
            .catch(() => {
                return false;
            });

        return valid;
    },
    message: () => getTrad('validations.uniqueRoleType'),
    lazy: true,
});
