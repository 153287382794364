import VuexPersistence from 'vuex-persist';
import appConfigStoreModule from '@core/@app-config/appConfigStoreModule';
import Vue from 'vue';
import Vuex from 'vuex';
import app from './app';
import auth from './app/auth';

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    key: process.env.VUE_APP_NAME,
});

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        appConfig: appConfigStoreModule,
        app,
        auth,
    },
    plugins: [vuexLocal.plugin],
});
