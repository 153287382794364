export default {
    GoogleSendPermissions: {
        error: 'Erro',
        errorText: 'Ocorreu um erro!',
    },

    CreateCalendar: {
        error: 'Erro',
        errorText: 'Ocorreu um erro ao criar um novo calendário!',
        success: 'Sucesso',
        successText: 'Calendário criado com sucesso!',
        name: 'Nome',
        description: 'Descrição',
        timezone: 'Fuso horário',
        create: 'Criar',
        cancel: 'Cancelar',
        required: 'Obrigatório',
        title: 'Criar Calendário',
    },

    RemoveCalendarDialog: {
        title: 'Remover Calendário',
        text: 'Tem a certeza de que pretende remover este calendário? Deixará de ter acesso a este calendário e aos respetivos eventos. Outras pessoas com acesso ao calendário podem continuar a utilizá-lo.',
        remove: 'Remover',
        cancel: 'Cancelar',
        error: 'Erro',
        errorText: 'Ocorreu um erro ao remover este calendário!',
    },

    CalendarLeftSidebarContent: {
        createEvent: 'Criar Evento',
        calendars: 'Calendários',
        createCalendar: 'Criar Calendário',
    },

    Calendar: {
        participate: 'Participar',
        attendees: 'Convidados',
        month: 'Mês',
        week: 'Semana',
        day: 'Dia',
    },

    CreateEvent: {
        name: 'Nome',
        calendar: 'Calendário',
        allDay: 'O dia todo',
        startDate: 'Data de início',
        endDate: 'Data de fim',
        startTime: 'Hora de início',
        endTime: 'Hora de fim',
        repetition: 'Repetição',
        createMeeting: 'Criar Reunião',
        invite: 'Convidar',
        location: 'Localização',
        description: 'Descrição',
        addNotification: 'Adicionar notificação',
        personalized: 'Personalizado...',
        create: 'Criar',
        clear: 'Limpar',
        minutesBefore: '{n} minuto antes | {n} minutos antes',
        hoursBefore: '{n} hora antes | {n} horas antes',
        daysBefore: '{n} dia antes | {n} dias antes',
        edit: 'Editar',
        event: 'Evento',
        noRepeat: 'Não se repete',
        daily: 'Diário',
        weekly: 'Semanal',
        monthly: 'Mensal',
        yearly: 'Anual',
        everyWeekday: 'Todos os dias da semana (segunda a sexta)',
        addMeeting: 'Adicionar videoconferência do Google Meet',
        removeMeeting: 'Remover videoconferência do Google Meet',
        errorText: 'Ocorreu um erro ao criar o evento!',
    },

    RepetitionDialog: {
        frequency: 'frequência',
        occurrences: 'Ocorrências',
        everyDay: 'Todos os dias ',
        every: 'A cada ',
        days: ' dias ',
        everyDayUntil: 'Todos os dias até ',
        daysUntil: ' dias até ',
        times: '{n} vez | {n} vezes',
        weeklyEveryDay: 'Semanalmente, todos os dias ',
        weekly: 'Semanalmente, ',
        weeksEveryDay: ' semanas, todos os dias ',
        weeks: ' semanas, ',
        weeklyEveryDayUntil: 'Semanalmente, todos os dias até ',
        weeklyEveryDayUntilLower: ' semanalmente, todos os dias até ',
        until: ' ,até ',
        weeksEveryDayUntil: ' semanas, todos os dias até ',
        monthlyOnTheDay: 'Mensalmente, no dia ',
        monthsAt: ' meses, no dia ',
        months: ' meses ',
        annuallyAt: 'Anualmente, a ',
        yearsAt: ' anos, até ',
        annuallyUntil: 'Anualmente, até ',
        annually: 'Anualmente, ',
        years: ' anos ',
        monthlyAt: 'Mensalmente no dia ',
        monthlyFirst: 'Mensalmente no(a) primeiro(a) ',
        monthlySecond: 'Mensalmente no(a) segundo(a) ',
        monthlyThird: 'Mensalmente no(a) terceiro(a) ',
        monthlyFourth: 'Mensalmente no(a) quarto(a) ',
        monthlyLast: 'Mensalmente nos(as) últimos(as) ',
        customFrequency: 'Periodicidade Personalizada',
        save: 'Concluído',
        cancel: 'Cancelar',
        repeatEvery: 'Repetir a cada',
        endsIn: 'Termina em',
        never: 'Nunca',
        after: 'Depois de',
        in: 'Em',
        repeat: 'Repetir',
        sunday: 'Domingo',
        monday: 'Segunda-feira',
        tuesday: 'Terça-feira',
        wednesday: 'Quarta-feira',
        thursday: 'Quinta-feira',
        friday: 'Sexta-feira',
        saturday: 'Sábado',
    },

    DialogAllDayNotification: {
        customNotification: 'Notificação personalizada',
        time: 'tempo',
        cancel: 'Cancelar',
        save: 'Concluído',
        notification: 'Notificação',
        email: 'Email',
        days: 'Dias',
        weeks: 'Semanas',
        beforeAt: ' antes, à(s) ',
        daysNumber: '{n} dia | {n} dias ',
        weeksNumber: '{n} semana | {n} semanas ',
        byEmail: ' ,por email',
    },
    DialogNotAllDayNotification: {
        customNotification: 'Notificação personalizada',
        time: 'tempo',
        cancel: 'Cancelar',
        save: 'Concluído',
        notification: 'Notificação',
        email: 'Email',
        days: 'Dias',
        weeks: 'Semanas',
        minutes: 'Minutos',
        hours: 'Horas',
        minutesNumber: '{n} minuto antes| {n} minutos antes',
        hoursNumber: '{n} hora antes| {n} horas antes',
        daysNumber: '{n} dia antes| {n} dias antes',
        weeksNumber: '{n} semana antes| {n} semanas antes',
        byEmail: ' ,por email',
    },

    RemoveEventDialog: {
        title: 'Remover Evento',
        text: 'Tem a certeza que pretende remover este evento?',
        remove: 'Remover',
        cancel: 'Cancelar',
        error: 'Erro',
        errorText: 'Ocorreu um erro ao remover este evento!',
    },
};
