import { localePath } from '../constants';
import { i18n } from '@/plugins/i18n';

export const getTrad = (id, ...args) => {
    return i18n.t(localePath(id), ...args);
};

export const getTradC = (id, ...args) => {
    return i18n.tc(localePath(id), ...args);
};

export default {
    computed: {
        getTrad() {
            return (id, ...args) => {
                return getTrad(id, ...args);
            };
        },
        getTradC() {
            return (id, ...args) => {
                return getTradC(id, ...args);
            };
        },
    },
};
