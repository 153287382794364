const mainRouter = require('@/router').default;
const mainStore = require('@/store').default;
const { i18n } = require('@/plugins/i18n');

const _modules = require
    .context('@/modules', true, /\.js$/)
    .keys()
    .map((key) => {
        return key.split('/')[1];
    });

const modules = [...new Set(_modules)];

for (let module of modules) {
    const { routeName, routePath, moduleName, active, pluginId, pluginName } = require(`@/modules/${module}/constants.js`).default;
    // console.log(`${pluginName} - ${active ? 'active' : 'inactive'}`);
    if (!active) continue;

    const routerModule = require(`@/modules/${module}/router`).default;
    const storeModule = require(`@/modules/${module}/store`).default;
    const navigation = require(`@/modules/${module}/navigation`).default;
    const locales = require(`@/modules/${module}/i18n`).default;
    try {
        require(`@/modules/${module}/validations`);
    } catch (error) {
        // console.log(error);
    }

    //! REGISTER STORE MODULES IN MAIN STORE
    //? store modules are registered in main store using the plugin id as prefix to avoid collisions with other plugins
    //? consider a plugin with the id 'google-calendar'
    //? e.g. store module with name "calendar" becomes accessible by "google-calendar/calendar"

    Object.keys(storeModule).forEach((key) => {
        if (!mainStore.hasModule(moduleName(key))) mainStore.registerModule(moduleName(key), storeModule[key]);
    });

    //! REGISTER ROUTES IN MAIN ROUTER
    //? route names are prefixed with the plugin id to avoid collisions with other plugins and to avoid collisions with the main routes
    //? considering a plugin with the id 'google-calendar'
    //? e.g. Route with name Home becomes GoogleCalendar-Home
    //? e.g. Route with path /home becomes /google-calendar/home
    routerModule.forEach((route) => {
        const regRoute = (route, isChildren = false) => {
            if (!isChildren) route.path = routePath(route.path);
            route.name = routeName(route.name);
            if (route.children) {
                route.children.forEach((child) => {
                    regRoute(child, true);
                });
            }
            return route;
        };

        route = regRoute(route);
        mainRouter.addRoute(route);
    });

    //! REGISTER NAVIGATION IN MAIN NAVIGATION
    mainStore.dispatch('app/addVerticalNavigationItem', navigation.vertical);

    //! REGISTER TRANSLATIONS IN MAIN TRANSLATIONS
    //? translations are registered in main translations using the plugin id as prefix to avoid collisions with other plugins
    //? considering a plugin with the id 'google-calendar'
    //? e.g. translation with id "calendar.title" becomes accessible by "google-calendar.calendar.title"
    Object.keys(locales).forEach((key) => {
        i18n.setLocaleMessage(key, {
            ...i18n.messages[key],
            [pluginId]: locales[key],
        });
    });
}
// remove duplicate
export default modules;
