import { merge, isArray } from 'lodash';
import user from './user';
import role from './role';
import media from './media';

const MODELS = {
    user,
    role,
    media,
};

export default function getModel(model, data = {}) {
    const MODEL = MODELS[model];
    if (isArray(data)) {
        return data.map((item) => merge({}, MODEL, item));
    }

    return merge({}, MODEL, data);
}
