import { camelCase, upperFirst } from 'lodash';
export const active = true;
export const pluginId = 'google-calendar';
export const pluginName = 'Google Calendar';
export const pluginDescription = 'Google Calendar plugin';
export const pluginVersion = '1.0.0';
export const permissions = {
    READ: 'google-calendar:read',
};
/**
 * It takes a string, converts it to camelCase, then capitalizes the first letter, then adds a hyphen
 * and the original string
 * @param name - The name of the route.
 * @returns The function routeName is being returned.
 */
export const routeName = (name) => {
    return upperFirst(camelCase(pluginId)) + '-' + name;
};
/**
 * It takes a path and returns a path with the pluginId prepended to it
 * @param path - The path to the route.
 * @returns A function that takes a path and returns a string.
 */
export const routePath = (path) => {
    return '/' + pluginId + path;
};
/**
 * It takes a string and returns a string.
 * @param name - The name of the module.
 * @returns The pluginId + '/' + name
 */
export const moduleName = (name) => {
    return pluginId + '/' + name;
};
export const localePath = (path) => {
    return pluginId + '.' + path;
};

export default {
    active,
    pluginId,
    pluginName,
    pluginDescription,
    pluginVersion,
    permissions,
    routeName,
    routePath,
    moduleName,
};
