import { routeName } from '../../constants';

const { mdiHomeAlert } = require('@mdi/js');

export default [
    /*     {
        title: 'Teste',
        icon: mdiHomeAlert,
        to: routeName('Calendar'),
        order: 1,
    }, */
    {
        title: 'Chat',
        icon: mdiHomeAlert,
        children: [
            {
                title: 'Criar Nova Sala',
                to: routeName('AddRoom'),
            },
            {
                title: 'Criar Utilizador',
                to: routeName('AddUser'),
            },
        ],
    },
];
