import { extend, configure, setInteractionMode } from 'vee-validate';
import { i18n } from '@/plugins/i18n';
import validator from 'validator';
import * as rules from 'vee-validate/dist/rules';
// setInteractionMode('lazy');
Object.keys(rules).forEach((rule) => {
    extend(rule, rules[rule]);
});
// extend('required', {
//     message: (_, values) => i18n.t('validations.required', values),
// });
extend('password-equal', {
    params: ['target'],
    validate(value, { target }) {
        return value === target;
    },
    message: (_, values) => i18n.t('validations.password-equal', values),
});

extend('not-hashed', {
    validate(value) {
        return value.split('$').length < 5;
    },
    message: (_, values) => i18n.t('validations.not-hashed', values),
});

extend('mobile-phone', {
    validate(value) {
        return validator.isMobilePhone(value);
    },
    message: (_, values) => i18n.t('validations.mobile-phone', values),
});

extend('postal-code', {
    validate(value) {
        return validator.isPostalCode(value, 'any');
    },
    message: (_, values) => i18n.t('validations.postal-code', values),
});

configure({
    defaultMessage: (field, values) => {
        // override the field name.

        values._field_ = field;
        return i18n.t(`validation.${values._rule_}`, values);
    },
});
