import store from '@/store';
import { mdiAlertCircleOutline, mdiChartTimelineVariant, mdiCheckCircleOutline, mdiClose, mdiContentSaveOutline } from '@mdi/js';
import { ref, watch } from '@vue/composition-api';
import moment from 'moment';

export default function useInvoicesList() {
    const invoiceListTable = ref([]);

    // Table Handlers
    const tableColumns = [
        {
            text: 'id',
            align: 'start',
            value: 'id',
            sortable: false,
        },
        { text: 'status', value: 'status', sortable: false },
        { text: 'client', value: 'client', sortable: false },
        { text: 'total', value: 'total', sortable: false },
        { text: 'date', value: 'date', sortable: false },
        { text: 'balanc', value: 'balance', sortable: false },
        {
            text: 'actions',
            value: 'actions',
            align: 'center',
            sortable: false,
        },
    ];
    const footerProps = {
        'items-per-page-options': [10, 20, 50],
        'items-per-page-text': '',
    };

    const searchQuery = ref('');
    const options = ref({});
    const totalInvoiceListTable = ref(0);
    const loading = ref(false);
    const statusFilter = ref(null);
    const selectedTableData = ref([]);

    const fetchInvoices = () => {
        store
            .dispatch('moloni-invoices/fetchInvoices', {
                q: searchQuery.value,
                pagination: options.value,
            })
            .then((response) => {
                const { data, meta } = response.data;
                invoiceListTable.value = data;
                totalInvoiceListTable.value = parseInt(meta.count);
                loading.value = false;
            })
            .catch((error) => {
                console.log(error);
            });
    };

    watch([searchQuery, statusFilter, options], () => {
        options.value.pageSize = options.value.itemsPerPage;
        // start loading
        loading.value = true;
        selectedTableData.value = [];
        fetchInvoices();
    });

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveInvoiceStatusVariantAndIcon = (item) => {
        if (item.reconciled_value > 0 && item.reconciled_value != item.net_value) return { variant: 'warning', icon: mdiChartTimelineVariant, text: 'partialPayment' };
        if (item.reconciled_value == item.net_value) return { variant: 'success', icon: mdiCheckCircleOutline, text: 'paid' };
        if (item.status == 0) return { variant: 'secondary', icon: mdiContentSaveOutline, text: 'draft' };
        if (moment().isAfter(moment(item.expiration_date))) return { variant: 'error', icon: mdiAlertCircleOutline, text: 'pastDue' };
        return { variant: 'secondary', icon: mdiClose, text: 'unpaid' };
    };

    const resolveClientAvatarVariant = (item) => {
        if (moment().isAfter(moment(item.expiration_date))) return 'error';
        if (item.status == 0) return 'secondary';
        if (item.reconciled_value > 0 && item.reconciled_value != item.net_value) return 'warning';
        if (item.reconciled_value == item.net_value) return 'success';

        return 'primary';
    };

    return {
        tableColumns,
        footerProps,
        searchQuery,
        options,
        invoiceListTable,
        statusFilter,
        totalInvoiceListTable,
        loading,
        selectedTableData,
        fetchInvoices,
        resolveInvoiceStatusVariantAndIcon,
        resolveClientAvatarVariant,
    };
}
