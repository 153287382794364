import { routeName } from '../../constants';

const { mdiHomeAlert } = require('@mdi/js');

export default [
    {
        title: 'Google Calendar',
        icon: mdiHomeAlert,
        to: routeName('Calendar'),
    },
];
