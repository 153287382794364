import { camelCase, upperFirst } from 'lodash';
export const active = true;
export const pluginId = 'rocket-chat';
export const pluginName = 'Rocket Chat';
export const pluginDescription = 'Rocket Chat Plugin';
export const pluginVersion = '1.0.0';
export const permissions = {
    READ: 'rocket-chat:read',
};
export const routeName = (name) => {
    return upperFirst(camelCase(pluginId)) + '-' + name;
};
export const routePath = (path) => {
    return '/' + pluginId + path;
};
export const moduleName = (name) => {
    return pluginId + '/' + name;
};
export const localePath = (path) => {
    return pluginId + '.' + path;
};

export default {
    active,
    pluginId,
    pluginName,
    pluginDescription,
    pluginVersion,
    permissions,
    routeName,
    routePath,
    moduleName,
};
