export default {
    id: 0,
    name: 'placeholder.png',
    alternativeText: 'placeholder.png',
    caption: 'placeholder.png',
    formats: {
        thumbnail: {
            name: 'placeholder_small.png',
            ext: '.png',
            mime: 'image/png',
            url: '/assets/img/product/placeholder_small.png',
        },
        small: {
            name: 'placeholder_small.png',
            ext: '.png',
            mime: 'image/png',
            url: '/assets/img/product/placeholder_small.png',
        },
    },
    ext: '.png',
    mime: 'image/png',
    url: '/assets/img/product/placeholder.png',
};
