import { camelCase, upperFirst } from 'lodash';
export const active = true;
export const pluginId = 'users-permissions';
export const pluginName = 'Users Permissions';
export const pluginDescription = 'Users Permissions';
export const pluginVersion = '1.0.0';
export const permissions = {
    READ: 'plugin::users-permissions.read',
};
export const routeName = (name) => {
    return upperFirst(camelCase(pluginId)) + '-' + name;
};
export const routePath = (path) => {
    return '/' + pluginId + path;
};
export const moduleName = (name) => {
    return pluginId + '/' + name;
};

export const localePath = (path) => {
    return pluginId + '.' + path;
};

export default {
    active,
    pluginId,
    pluginName,
    pluginDescription,
    pluginVersion,
    permissions,
    routeName,
    routePath,
    moduleName,
    localePath,
};
