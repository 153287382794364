import { routeName } from '../../constants';

const { mdiAccount, mdiLock } = require('@mdi/js');

export default [
    {
        title: 'Users',
        icon: mdiAccount,
        order: 1,
        to: routeName('Users-List'),
    },
    {
        title: 'Roles',
        icon: mdiLock,
        to: routeName('Roles-List'),
    },
];
