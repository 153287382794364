<template>
  <div class="vertical-nav-menu-container" @mouseenter="isMouseHovered = true" @mouseleave="isMouseHovered = false">
    <slot name="v-nav-menu-header">
      <vertical-nav-menu-header @close-nav-menu="$emit('close-nav-menu')"></vertical-nav-menu-header>
    </slot>

    <!-- Shadow -->
    <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />

    <!-- Navigation Drawer: User Profile -->
    <v-navigation-drawer
      v-model="isUserProfileSidebarOpen"
      width="374"
      absolute
      :right="$vuetify.rtl"
      touchless
      temporary
    >
      <chat-user-profile-sidebar-content
        :profile-user-data="profileUserData"
        @close-user-profile-sidebar="isUserProfileSidebarOpen = false"
      ></chat-user-profile-sidebar-content>
    </v-navigation-drawer>

    <perfect-scrollbar
      class="ps-nav-menu-items"
      :options="perfectScrollbarOptions"
      @ps-scroll-y="
        evt => {
          shallShadowBottom = evt.srcElement.scrollTop > 0
        }
      "
    >
      <v-list expand shaped class="vertical-nav-menu-items">
        <v-slide-x-transition>
          <chat-left-sidebar-content
            :chats-contacts="chatsContacts"
            :contacts="contacts"
            :active-chat-contact-id="null"
            :profile-user-minimal-data="profileUserDataMinimal"
            @open-chat="goToChat"
            @open-chat-user-profile-sidebar-content="isUserProfileSidebarOpen = true"
            @close-chat="closeChat"
            v-show="isChatActive"
          />
        </v-slide-x-transition>

        <!-- <v-list class="py-0" v-show="!isChatActive" transition="slide-x-reverse-transition">
          <v-list-item class="d-flex" link @click="openChat">
            <v-icon class="me-3">
              {{ messageIcon }}
            </v-icon>
            <span>Chat</span>
          </v-list-item>
          <v-list-item-group active-class="bg-gradient-primary white--text">
            <v-list-item class="d-flex" link to="/charts-and-maps/charts/create-chart">
              <v-icon class="me-3">
                {{ chartIcon }}
              </v-icon>
              <span>Gráficos</span>
            </v-list-item>
            <v-list-item class="d-flex" link to="/charts-and-maps/charts/dashboards">
              <v-icon class="me-3">
                {{ dashboatdIcon }}
              </v-icon>
              <span>Dashboards</span>
            </v-list-item>
            <v-list-item class="d-flex" link to="/apps/calendar">
              <v-icon class="me-3">
                {{ calendarIcon }}
              </v-icon>
              <span>Calendário</span>
            </v-list-item>
          </v-list-item-group>

          <v-list-item-group active-class="bg-gradient-primary white--text">
            <v-list-group :value="true">
              <template v-slot:activator>
                <v-list-item-title>Rocket Chat</v-list-item-title>
              </template>

              <v-list-item-group active-class="bg-gradient-primary white--text">
                <v-list-item class="d-flex" link to="/chat/new-user">
                  <v-icon class="me-3">
                    {{ addUserIcon }}
                  </v-icon>
                  <span>Adicionar utilizador</span>
                </v-list-item>
              </v-list-item-group>

              <v-list-item-group active-class="bg-gradient-primary white--text">
                <v-list-item class="d-flex" link to="/chat/new-room">
                  <v-icon class="me-3">
                    {{ newRoomIcon }}
                  </v-icon>
                  <span>Criar nova sala</span>
                </v-list-item>
              </v-list-item-group>
            </v-list-group>
          </v-list-item-group>
        </v-list> -->

          <component
          :is="resolveNavItemComponent(item)"
          v-for="item in navMenuItems"
          :key="item.subheader || item.title"
          :item="item"
        ></component>
      </v-list>
    </perfect-scrollbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref, provide, nextTick } from '@vue/composition-api'

// SFCs
import VerticalNavMenuHeader from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-header/VerticalNavMenuHeader.vue'
import VerticalNavMenuSectionTitle from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-section-title/VerticalNavMenuSectionTitle.vue'
import VerticalNavMenuGroup from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-group/VerticalNavMenuGroup.vue'
import VerticalNavMenuLink from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-link/VerticalNavMenuLink.vue'

// Composable
import useVerticalNavMenu from '@/@core/layouts/composable/vertical-nav/useVerticalNavMenu'

// 3rd Party
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

//Tests
import ChatLeftSidebarContent from '@/views/apps/chat/ChatLeftSidebarContent.vue'
import store from '@/store'
import chatStoreModule from '@/views/apps/chat/chatStoreModule'
import ChatContact from '@/views/apps/chat/ChatContact.vue'
import { until, invoke } from '@vueuse/core'
import ChatUserProfileSidebarContent from '@/views/apps/chat/ChatUserProfileSidebarContent.vue'

import {
  mdiMessageTextOutline,
  mdiChartBar,
  mdiAccountPlus,
  mdiChatPlus,
  mdiViewDashboard,
  mdiCalendarOutline,
} from '@mdi/js'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuGroup,
    VerticalNavMenuLink,
    VerticalNavMenuSectionTitle,

    // 3rd Party
    PerfectScrollbar,

    //Tests
    ChatLeftSidebarContent,
    ChatContact,
    ChatUserProfileSidebarContent,
  },
  props: {
    navMenuItems: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      chatsContacts: [],
      contacts: [],
      contact: null,
      isUserProfileSidebarOpen: false,
      profileUserData: {},
      messageIcon: mdiMessageTextOutline,
      chartIcon: mdiChartBar,
      isChatActive: false,
      addUserIcon: mdiAccountPlus,
      newRoomIcon: mdiChatPlus,
      dashboatdIcon: mdiViewDashboard,
      calendarIcon: mdiCalendarOutline,
    }
  },

  methods: {
    goToChat(contactID) {
      let userId = contactID
      store.dispatch('app-chat/getChat', { userId }).then(response => {
        // Set unseenMsgs to 0
        this.contact = this.chatsContacts.find(c => c.id === userId)

        if (this.contact) this.contact.chat.unseenMsgs = 0
      })

      if (this.$route.params.contactID !== contactID) {
        this.$router.push({ name: 'chat-test', params: { contactID: contactID } })
      }
    },

    fetchChatAndContacts() {
      store.dispatch('app-chat/fetchChatsAndContacts').then(response => {
        this.chatsContacts = response.data.chatsContacts
        this.contacts = response.data.contacts
        this.profileUserDataMinimal = response.data.profileUser
      })
    },

    openChat() {
      this.$emit('is-chat-open', true)
      this.isChatActive = true
    },
    closeChat() {
      this.$emit('is-chat-open', false)
      this.isChatActive = false
    },
  },

  created() {
    invoke(async () => {
      store.dispatch('app-chat/getProfileUser').then(response => {
        this.profileUserData = response.data
      })
    })
    this.fetchChatAndContacts()
  },

  computed: {
    count() {
      return this.$store.getters['app-chat/getUpdateChatState']
    },
  },

  watch: {
    count(newCount, oldCount) {
      this.fetchChatAndContacts()
      store.commit('app-chat/setUpdateChatState', false)
    },
  },

  setup() {
    const { resolveNavItemComponent, isMouseHovered } = useVerticalNavMenu()
    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('openGroups', ref([]))

    //Test Contacts

    // * Store module Registration
    const CHAT_APP_STORE_MODULE_NAME = 'app-chat'

    // Register module
    if (!store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.registerModule(CHAT_APP_STORE_MODULE_NAME, chatStoreModule)

    const chatsContacts = ref([])
    const contacts = ref([])
    const profileUserDataMinimal = ref({})
    const activeChat = ref({})
    const chatInputMessage = ref('')

    return {
      resolveNavItemComponent,
      perfectScrollbarOptions,
      isMouseHovered,
      shallShadowBottom,

      // Chat & Contacts
      chatsContacts,
      contacts,
      profileUserDataMinimal,
      activeChat,
      chatInputMessage,
    }
  },
}
</script>

<style lang="scss">
@import '~vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
</style>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';
@import '~@core/preset/preset/mixins.scss';

.app-navigation-menu {
  transition-duration: 0.4s !important;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1), ease !important;
  will-change: box-shadow, transform !important;

  .v-list-item,
  .v-subheader {
    padding: 0 18px 0 22px;
  }

  .v-list-item {
    margin-top: 0.375rem;
  }

  .v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
    min-width: 24px;
  }

  .v-navigation-drawer__border {
    background-color: transparent !important;
  }

  // &.v-navigation-drawer--open-on-hover.v-navigation-drawer--is-mouseover {
  //   @include elevation(9);
  //   @include elevationTransition();
  // }
}

@include app-elevation('app-navigation-menu.v-navigation-drawer--open-on-hover.v-navigation-drawer--is-mouseover', 9);

// Set Perfect Scrollbar Container Height
.vertical-nav-menu-container {
  height: 100%;
  .ps-nav-menu-items {
    height: calc(100% - 57px) !important;
  }

  // menu scroll shadow
  .shadow-bottom {
    display: none;
    position: absolute;
    z-index: 2;
    height: 50px;
    width: 100%;
    left: -6px;
    pointer-events: none;
    margin-top: -0.7rem;
    filter: blur(5px);
  }
}

@include theme--child(vertical-nav-menu-container) using ($material) {
  .shadow-bottom {
    background: linear-gradient(
      map-deep-get($material, 'background') 40%,
      rgba(map-deep-get($material, 'background'), 0.1) 95%,
      rgba(map-deep-get($material, 'background'), 0.05)
    );
    margin-top: -0.5rem;
    filter: blur(4px);
  }
}

@include theme(vertical-nav-menu-items) using ($material) {
  @include ltr() {
    padding-right: 20px !important;
  }
  @include rtl() {
    padding-left: 20px !important;
  }
  @at-root .app-navigation-menu.v-navigation-drawer--mini-variant:not(.v-navigation-drawer--is-mouseover) {
    .vertical-nav-menu-items {
      @include ltr() {
        padding-right: 5px !important;
      }
      @include rtl() {
        padding-left: 5px !important;
      }
    }
  }

  .v-subheader:not(:first-child) {
    margin-top: 18px;
  }

  .v-list-item__icon {
    &:first-child {
      @include ltr() {
        margin: 0 12px 0 0;
      }
      @include rtl() {
        margin: 0 0 0 12px;
      }
      align-self: center;
    }
    &.v-list-group__header__append-icon {
      @include ltr() {
        margin-left: 4px;
      }
      @include rtl() {
        margin-right: 4px;
      }
    }
  }

  // Center align small icon
  .v-list-group__items .v-list-item__icon {
    margin-top: 0px;
    @include ltr() {
      margin-left: 5px;
    }
    @include rtl() {
      margin-right: 5px;
    }
  }

  .v-list-item {
    @include app-states($material);

    height: 44px;
    min-height: 44px;
    color: map-deep-get($material, 'text', 'primary');
    // ? Reason: In collapsed mode due to modified mini width & flex grow icon change position while collapsing the drawer
    justify-content: flex-start !important;

    &.v-list-item--active {
      &:not(.v-list-group__header) {
        color: white;
        // background-image: linear-gradient(98deg, #c48eff, var(--v-primary-base) 94%);
      }
      &.v-list-group__header {
        background-color: rgba(
          map-deep-get($material, 'text', 'primary'),
          map-deep-get($material, 'states', 'selected')
        );
      }
    }

    &.vertical-nav-menu-link {
      .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}
</style>
