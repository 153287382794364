export default {
    Dashboards: 'Dashboards',
    CRM: 'CRM',
    Analytics: 'Analytics',
    eCommerce: 'eCommerce',
    Calendar: 'Calendar',
    Chat: 'Chat',
    Email: 'Email',
    Invoice: 'Invoice',
    List: 'List',
    Preview: 'Preview',
    Edit: 'Edit',
    Add: 'Add',
    User: 'User',
    'User List': 'User List',
    'User View': 'User View',
    'APPS AND PAGES': 'APPS AND PAGES',
    Pages: 'Pages',
    Authentication: 'Authentication',
    'Login V1': 'Login V1',
    'Login V2': 'Login V2',
    'Register V1': 'Register V1',
    'Register V2': 'Register V2',
    'Forgot Password V1': 'Forgot Password V1',
    'Forgot Password V2': 'Forgot Password V2',
    'Reset Password V1': 'Reset Password V1',
    'Reset Password V2': 'Reset Password V2',
    Miscellaneous: 'Miscellaneous',
    'Under Maintenance': 'Under Maintenance',
    Error: 'Error',
    'Coming Soon': 'Coming Soon',
    'Not Authorized': 'Not Authorized',
    'Knowledge Base': 'Knowledge Base',
    'Account Settings': 'Account Settings',
    Pricing: 'Pricing',
    FAQ: 'FAQ',
    'USER INTERFACE': 'USER INTERFACE',
    Typography: 'Typography',
    Icons: 'Icons',
    Gamification: 'Gamification',
    Cards: 'Cards',
    Basic: 'Basic',
    Statistics: 'Statistics',
    Advance: 'Advance',
    Actions: 'Actions',
    Chart: 'Chart',
    Components: 'Components',
    Alert: 'Alert',
    Avatar: 'Avatar',
    Badge: 'Badge',
    Button: 'Button',
    Menu: 'Menu',
    'Expansion Panel': 'Expansion Panel',
    Dialog: 'Dialog',
    Pagination: 'Pagination',
    Tabs: 'Tabs',
    Tooltip: 'Tooltip',
    Chip: 'Chip',
    Snackbar: 'Snackbar',
    Stepper: 'Stepper',
    Timeline: 'Timeline',
    Treeview: 'Treeview',
    'FORMS & TABLES': 'FORMS & TABLES',
    'Forms Elements': 'Forms Elements',
    Autocomplete: 'Autocomplete',
    Checkbox: 'Checkbox',
    Combobox: 'Combobox',
    'File Input': 'File Input',
    Radio: 'Radio',
    'Range Slider': 'Range Slider',
    Select: 'Select',
    Slider: 'Slider',
    Switch: 'Switch',
    Textarea: 'Textarea',
    Textfield: 'Textfield',
    'Date Picker': 'Date Picker',
    'Month Picker': 'Month Picker',
    'Time Picker': 'Time Picker',
    Rating: 'Rating',
    'Form Layout': 'Form Layout',
    'Form validation': 'Form validation',
    Tables: 'Tables',
    'Simple Table': 'Simple Table',
    Datatable: 'Datatable',
    'CHARTS AND MAPS': 'CHARTS AND MAPS',
    Charts: 'Charts',
    'Apex Chart': 'Apex Chart',
    Chartjs: 'Chartjs',
    'Leaflet Maps': 'Leaflet Maps',
    Others: 'Others',
    'Menu Levels': 'Menu Levels',
    'Menu Level 2.1': 'Menu Level 2.1',
    'Menu Level 2.2': 'Menu Level 2.2',
    'Menu Level 3.1': 'Menu Level 3.1',
    'Menu Level 3.2': 'Menu Level 3.2',
    'Disabled Menu': 'Disabled Menu',
    'Raise Support': 'Raise Support',
    Documentation: 'Documentation',
    'Charts & Maps': 'Charts & Maps',
    Apps: 'Apps',
    'User Interface': 'User Interface',
    'Forms & Tables': 'Forms & Tables',
    Login: {
        title: 'Welcome to ioConnect! 👋🏻',
        subtitle: 'Please login to your account and start the adventure',
        forgotPassword: 'Forgot Password?',
        fields: {
            email: 'Email',
            password: 'Password',
            button: 'Login',
        },
        api: {
            'Invalid identifier or password': 'Wrong email or password',
        },
    },
    forgotPassword: {
        title: 'Forgot Password? 🔒',
        subtitle: "Enter your email and we'll send you instructions to reset your password",
        fields: {
            email: 'Email',
            button: 'Send reset link',
        },
        api: {
            'Invalid identifier': 'Wrong email',
            success: 'We have sent you an email with instructions to reset your password',
        },
        tryAgain: 'Try again in {0} seconds',
        backLogin: 'Back to login',
    },
    validations: {
        'mobile-phone': 'Please enter a valid phone number',
        'password-equal': 'Passwords must be equal',
        'not-hashed': "The {_field_} can't' have more than 3 times the symbol '$'",
        'postal-code': 'Please enter a valid postal code',
        invalidEmail: 'Invalid email or username',
        invalidPassword: 'Invalid password',
    },
    shared: {
        or: 'or',
        commonError: 'An error has occurred',
    },
};
