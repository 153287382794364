import { routeName } from '../../constants';

const { mdiHomeAlert } = require('@mdi/js');

export default [
    {
        title: 'Teste',
        icon: mdiHomeAlert,
        to: routeName('Calendar'),
        order: 1,
    },
];
