import axios from '@axios';
import qs from 'qs';

export function getUsers(query = {}) {
    query = qs.stringify(query);
    return axios.get(`/users?${query}`);
}

export function countUsers(query = {}) {
    query = qs.stringify(query);
    return axios.get(`/users/count?${query}`);
}

export function addUser(data, query = {}) {
    query = qs.stringify(query);
    return axios.post(`/users?${query}`, { data });
}

export function deleteUser(id) {
    return axios.delete(`/users/${id}`);
}

export function updateUser(id, data, query = {}) {
    query = qs.stringify(query);
    return axios.put(`/users/${id}?${query}`, { data });
}

export function deleteManyUsers(ids) {
    let query = qs.stringify({ ids });
    return axios.delete(`/users?${query}`);
}
