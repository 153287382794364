//! REGISTER ROUTES IN MAIN ROUTER
//? route names are prefixed with the plugin id to avoid collisions with other plugins and to avoid collisions with the main routes
//? considering a plugin with the id 'google-calendar'
//? e.g. Route with name "Home" becomes "GoogleCalendar-Home"
//? e.g. Route with path "/home" becomes "/google-calendar/home"

import { routeName } from '../constants';

export default [
    {
        name: 'Users',
        path: '/users',
        component: () => import('../views/Users.vue'),
        redirect: { name: routeName('Users-List') },
        meta: {
            layout: 'content',
        },
        children: [
            {
                path: 'list',
                name: 'Users-List',
                component: () => import('../views/Users/List.vue'),
            },
            {
                path: 'details/:id',
                name: 'Users-Details',
                component: () => import('../views/Users/Details.vue'),
            },
            {
                path: 'add',
                name: 'Users-Add',
                component: () => import('../views/Users/Form.vue'),
            },
        ],
    },

    {
        path: '/roles',
        name: 'Roles',
        component: () => import('../views/Roles.vue'),
        meta: {
            layout: 'content',
        },
        children: [
            {
                path: '',
                name: 'Roles-List',
                component: () => import('../views/Roles/List.vue'),
            },
            {
                path: '',
                name: 'Roles-Details',
                component: () => import('../views/Roles/Details.vue'),
            },
            {
                path: '',
                name: 'Roles-Add',
                component: () => import('../views/Roles/Form.vue'),
            },
        ],
    },
];
