import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import apps from './apps';
import dashboards from '../modules/dashboards/router/index';
import calendar from '../modules/google-calendar/router/index';

Vue.use(VueRouter);

let routes = [
    {
        path: '/',
        redirect: '/dashboards/home',
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('@/views/Home.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/second-page',
        name: 'second-page',
        component: () => import('@/views/SecondPage.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/auth',
        name: 'Auth',
        redirect: '/auth/login',
        component: () => import('@/views/Auth.vue'),
        meta: {
            layout: 'blank',
            noAuth: true,
        },
        children: [
            {
                path: 'login',
                name: 'Login',
                component: () => import('@/views/forms/Login.vue'),
            },
            {
                path: 'forgot-password',
                name: 'ForgotPassword',
                component: () => import('@/views/forms/ForgotPassword.vue'),
            },
        ],
    },
    {
        path: '*',
        component: () => import('@/views/Error404.vue'),
    },

    ...apps,
    ...dashboards,
    ...calendar,
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});

router.beforeEach((to, from, next) => {
    if (process.env.VUE_APP_NO_AUTH) {
        next();
    } else {
        if (store.getters['auth/getToken']) {
            if (to.matched.some((record) => record.meta.noAuth)) {
                next({ name: 'home' });
            } else {
                next();
            }
        } else {
            if (to.matched.some((record) => record.meta.noAuth)) {
                next();
            } else {
                next({ name: 'Login' });
            }
        }
    }
});

export default router;
