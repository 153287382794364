import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import preset from '@/@core/preset/preset';
import store from '@/store';
import { has } from 'lodash';

Vue.use(Vuetify);

Vue.prototype.$snackbar = (data) => {
    if (!has(data, 'show')) data.show = true;
    store.dispatch('app/snackbar', data);
};

export default new Vuetify({
    preset,
    icons: {
        iconfont: 'mdiSvg',
    },
    theme: {
        options: {
            customProperties: true,
            variations: true,
        },
    },
});
