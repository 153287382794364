//! REGISTER ROUTES IN MAIN ROUTER
//? route names are prefixed with the plugin id to avoid collisions with other plugins and to avoid collisions with the main routes
//? considering a plugin with the id 'google-calendar'
//? e.g. Route with name "Home" becomes "GoogleCalendar-Home"
//? e.g. Route with path "/home" becomes "/google-calendar/home"

export default [
    {
        path: '/home',
        name: 'Dashboards',
        component: () => import('@/modules/dashboards/views/Dashboards.vue'),
        meta: {
            layout: 'content',
        },
    },
];
