import { routeName } from '../../constants';

const { mdiViewDashboard } = require('@mdi/js');

export default [
    {
        title: 'Dashboards',
        icon: mdiViewDashboard,
        to: routeName('Dashboards'),
        order: 1,
    },
];
