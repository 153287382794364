<template>
    <v-snackbar @input="onInput" app top centered v-model="getSnackbar.show" :color="getSnackbar.color" :timeout="getSnackbar.timeout">
        {{ getSnackbar.text }}
    </v-snackbar>
</template>

<script>
    export default {
        name: 'Snackbar',
        computed: {
            getSnackbar() {
                return this.$store.getters['app/snackbar'];
            },
        },
        methods: {
            onInput() {
                this.$store.dispatch('app/snackbar', { show: false });
            },
        },
    };
</script>

<style lang="scss" scoped></style>
