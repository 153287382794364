import '@/plugins/vue-composition-api';
import '@/styles/styles.scss';

import Vue from 'vue';
import App from './App.vue';
import { i18n } from './plugins/i18n';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
store.commit('app/RESET_NAVIGATION');
import '@/plugins/vee-validate';
require('@/plugins/modules');

import Moment from 'moment';
Moment.locale(process.env.VUE_APP_I18N_LOCALE);
Vue.prototype.$moment = Moment;

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: (h) => h(App),
}).$mount('#app');
