//! REGISTER ROUTES IN MAIN ROUTER
//? route names are prefixed with the plugin id to avoid collisions with other plugins and to avoid collisions with the main routes
//? considering a plugin with the id 'google-calendar'
//? e.g. Route with name "Home" becomes "GoogleCalendar-Home"
//? e.g. Route with path "/home" becomes "/google-calendar/home"

export default [
    {
        path: '/invoices',
        name: 'moloni-invoices',
        component: () => import('@/modules/moloni/views/invoice/invoice-list/InvoiceList.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/invoices/:id',
        name: 'moloni-invoice-preview',
        component: () => import('@/modules/moloni/views/invoice/invoice-preview/InvoicePreview.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/receipts',
        name: 'moloni-receipts',
        component: () => import('@/modules/moloni/views/receipt/receipt-list/ReceiptList.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/receipts/:id',
        name: 'moloni-receipt-preview',
        component: () => import('@/modules/moloni/views/receipt/receipt-preview/ReceiptPreview.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/purchase-orders',
        name: 'moloni-purchase-orders',
        component: () => import('@/modules/moloni/views/purchase-order/purchase-order-list/PurchaseOrderList.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/purchase-orders/:id',
        name: 'moloni-purchase-order-preview',
        component: () => import('@/modules/moloni/views/purchase-order/purchase-order-preview/PurchaseOrderPreview.vue'),
        meta: {
            layout: 'content',
        },
    },
];
