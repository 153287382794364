import axios from '@axios';
import qs from 'qs';
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchPurchaseOrders(ctx, queryParams) {
            let query = qs.stringify({
                ...queryParams.query,
                pagination: queryParams.pagination,
            });
            return new Promise((resolve, reject) => {
                axios
                    .get(`/moloni/purchase-orders?${query}`)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },
        fetchPurchaseOrder(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/moloni/purchase-orders/${id}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        fetchPurchaseOrderDownloadLink(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/moloni/purchase-orders/${id}/pdf`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
    },
};
